export default {
  "card": "cIL",
  "focus": "cIT",
  "title-format": "cIz",
  "header": "cIa",
  "headings": "cIH",
  "title": "cIO title-4",
  "main": "cIm title-3",
  "footer": "cIp"
};
