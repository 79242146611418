export default {
  "navigation-dropdown": "cPZ",
  "expanded": "cPR",
  "trigger": "cPQ",
  "trigger-title-icon": "cPe",
  "collapsed": "cPB",
  "responsive": "cPE",
  "logo": "cPd",
  "active": "cPI",
  "trigger-title": "cPt",
  "content": "cPM",
  "content-container": "cPP",
  "dropdown-icon": "cPl",
  "link": "cPX"
};
