export default {
  "header-cell": "cDV",
  "checkbox-cell": "cDL",
  "title-col": "cDT",
  "internal-note-col": "cDz",
  "unit-col": "cDa",
  "price-col": "cDH",
  "header-content": "cDO caption-bold",
  "active": "cDm",
  "empty": "cDp",
  "align-checkbox": "cDx"
};
