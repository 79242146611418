export default {
  "item": "cMc",
  "selected": "cMq",
  "hover-revised": "cMZ",
  "asset-container": "cMR",
  "asset": "cMQ",
  "revoked-icon": "cMe",
  "pending-icon": "cMB",
  "revoked": "cME",
  "revised-revoke": "cMd",
  "pending": "cMI",
  "revised-pending": "cMt",
  "invitable": "cMM",
  "info": "cMP",
  "name": "cMl",
  "email": "cMX",
  "actions": "cMC",
  "invitable-member-delete": "cMk",
  "invitable-member-invite": "cMJ"
};
