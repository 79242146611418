export default {
  "sidebar": "cdd",
  "top-section": "cdI",
  "title": "cdt",
  "primary-action": "cdM",
  "edit-icon": "cdP",
  "close-icon": "cdl",
  "box-wrapper": "cdX",
  "bottom-action": "cdC",
  "related-invoices": "cdk",
  "related-invoices-title": "cdJ mb-16 caption-bold",
  "box": "cds",
  "box-element": "cdF",
  "cancel-text": "cdg",
  "box-header": "cdD",
  "row": "cdV",
  "greyed-out": "cdL",
  "struck-through": "cdT",
  "share-mandate-link": "cdz"
};
