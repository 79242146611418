export default {
  "body": "cMs",
  "members": "cMF",
  "members-results": "cMg",
  "members-list": "cMD",
  "members-title": "cMV caption-bold",
  "member": "cML",
  "empty-illustration": "cMT",
  "empty-title": "cMz title-3",
  "empty-description": "cMa body-2",
  "invitable-members": "cMH",
  "details": "cMO",
  "pagination-footer": "cMm"
};
