/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';
import { PaymentMethodTitle } from '@repo/domain-kit/shared';
import { IconAttachmentOutlined, IconLinkOutlined } from '@repo/monochrome-icons';
import { reads } from 'macro-decorators';

import {
  PAYMENT_LINK_BADGE_LEVEL,
  PAYMENT_LINK_STATUS,
  PAYMENT_LINK_STATUS_ICONS,
} from 'qonto/constants/payment-links';
import type PaymentLinkModel from 'qonto/models/payment-link';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';

interface PaymentLinksTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isSidebarOpen?: boolean;
    item: PaymentLinkModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class PaymentLinksTableItem extends Component<PaymentLinksTableItemSignature> {
  statusAvatar = StatusAvatar;
  iconLinkOutlined: typeof IconLinkOutlined = IconLinkOutlined;
  iconAttachmentOutlined: typeof IconAttachmentOutlined = IconAttachmentOutlined;

  @service declare intl: Services['intl'];

  badgeStatus = BadgeStatus;
  paymentMethodTitle = PaymentMethodTitle;

  // @ts-expect-error
  @reads('args.item.status') status;

  get isCancelled() {
    return [PAYMENT_LINK_STATUS.CANCELED, PAYMENT_LINK_STATUS.EXPIRED].includes(this.status);
  }

  get avatarIcon() {
    return PAYMENT_LINK_STATUS_ICONS[this.status];
  }

  get numberOfAdditionalItems() {
    if (!this.args.item.items || this.args.item.items.length <= 1) {
      return 0;
    }
    return this.args.item.items.length - 1;
  }

  get badgeStatusProps() {
    let text;

    switch (this.status) {
      case PAYMENT_LINK_STATUS.PAID:
        text = this.intl.t('payment-links.payment-link-status.completed');
        break;
      case PAYMENT_LINK_STATUS.CANCELED:
        text = this.intl.t('payment-links.payment-link-status.cancelled');
        break;
      case PAYMENT_LINK_STATUS.EXPIRED:
        text = this.intl.t('payment-links.payment-link-status.expired');
        break;
      case PAYMENT_LINK_STATUS.OPEN:
      default:
        text = this.intl.t('payment-links.payment-link-status.active');
        break;
    }

    return {
      level: PAYMENT_LINK_BADGE_LEVEL[this.status],
      text,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Table::Item': typeof PaymentLinksTableItem;
  }
}
