export default {
  "card": "clK",
  "header": "clG",
  "avatar": "clr",
  "title": "clb",
  "subtitle": "cly",
  "label": "clU",
  "item": "clj body-2",
  "header-icon": "clW",
  "dropdown-menu": "clu",
  "menu-item": "cXS body-2",
  "delete": "cXc"
};
