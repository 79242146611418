export default {
  "row": "clF",
  "terminated": "clg",
  "status-avatar": "clD",
  "amount": "clV body-1",
  "item-info": "clL",
  "date-cell": "clT",
  "cell": "clz body-2",
  "status-icon": "cla",
  "amount-cell": "clH",
  "item-name-cell": "clO",
  "item-name-container": "clm"
};
