import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import * as Sentry from '@sentry/ember';
// @ts-expect-error
import { bisectLeft } from 'd3-array';
// @ts-expect-error
import { axisBottom, axisLeft } from 'd3-axis';
// @ts-expect-error
import { easeBackInOut, easeCubicInOut } from 'd3-ease';
// @ts-expect-error
import { interpolate } from 'd3-interpolate';
// @ts-expect-error
import { scaleBand, scaleLinear } from 'd3-scale';
// @ts-expect-error
import { select } from 'd3-selection';
// @ts-expect-error
import { line } from 'd3-shape';
import dayjs from 'dayjs';

import { canAnimate, metricSuffixer } from 'qonto/utils/chart';
// @ts-expect-error
import { getExtrema } from 'qonto/utils/chart/scale';

interface ComboChartSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

interface TimeseriesDataPoint {
  time: Date;
  data: DataPoint;
}

interface DataPoint {
  isCurrent: boolean;
  isFuture: boolean;
  currentBalance: number;
  startingBalance: number;
  endingBalance: number;
  forecastedStartingBalance: number;
  forecastedEndingBalance: number;
  projectedStartingBalance: number | undefined;
  projectedEndingBalance: number | undefined;
  netChange: number;
  forecastedNetChange: number;
  forecastedCurrentNetChange: number;
  projectedNetChange: number | undefined;
  vatCredit: number;
  vatDebit: number;
  vatDue: number;
  inflows: number;
  inflowsForecast: number;
  projectedForecastInflows: number;
  projectedForecastOutflows: number;
  outflows: number;
  outflowsForecast: number;
}

export default class ComboChartComponent extends Component<ComboChartSignature> {
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  animationDelay = 0;
  animationDuration = 800;
  chartSpacing = {
    top: 30,
    left: 40,
    leftYAxis: 40,
    vertical: 8,
    yScaleTop: 12,
  };
  defaultYMax = 50000;
  headroom = 1.3;
  guid = guidFor(this);
  isFirstDraw = true;
  // @ts-expect-error
  tickFormatter = d =>
    // @ts-expect-error
    dateToken({ date: d, locale: this.intl.primaryLocale, token: 'month-year-s' });

  get displayedMonths() {
    // @ts-expect-error
    return this.args.displayedMonths || 12;
  }

  get defaultSeries() {
    return Array.from({ length: this.displayedMonths }).map((_, index) => ({
      time: new Date(new Date().getFullYear(), index),
      data: { value: 0 },
    }));
  }

  get chartInnerHeight() {
    // @ts-expect-error
    let containerHeight = this.chartContainer?.clientHeight;
    return containerHeight - this.chartSpacing.top - this.chartSpacing.vertical * 2;
  }

  get chartWidth() {
    // @ts-expect-error
    return this.chartContainer?.clientWidth;
  }

  get hasData() {
    // @ts-expect-error
    return this.args.timeseries?.some?.(({ data }) => data);
  }

  get timeseries() {
    // @ts-expect-error
    return this.hasData ? this.args.timeseries : this.defaultSeries;
  }

  get isAnimated() {
    return this.isFirstDraw && canAnimate();
  }

  get currentDateValue() {
    // @ts-expect-error
    switch (this.args.rate) {
      case 'daily':
        return dayjs().startOf('day').toDate();
      case 'weekly':
        return dayjs().startOf('isoWeek').toDate();
      default:
        return dayjs().startOf('month').toDate();
    }
  }

  get isCurrentPeriodDisplayed() {
    return (
      this.hasData && dayjs().startOf('day').isBetween(this.startBound, this.endBound, 'day', '[]')
    );
  }

  get currentDateIndex() {
    if (this.isCurrentPeriodDisplayed) {
      let maxIndex = this.timeseries.length - 1;
      let index = bisectLeft(
        // @ts-expect-error
        this.timeseries.map(({ time }) => time),
        this.currentDateValue
      );

      return index < maxIndex ? index : maxIndex;
    } else {
      return -1;
    }
  }

  get startBound() {
    // @ts-expect-error
    return this.args.bounds?.min || this.args.timeseries[0].time;
  }

  get endBound() {
    // @ts-expect-error
    return this.args.bounds?.max || this.args.timeseries.at(-1).time;
  }

  get isFutureRange() {
    // @ts-expect-error
    if (this.args.timeseries) {
      return dayjs(this.startBound).isAfter(dayjs());
    } else {
      return false;
    }
  }

  get isPastRange() {
    // @ts-expect-error
    if (this.args.timeseries) {
      return dayjs(this.endBound).isBefore(dayjs());
    } else {
      return false;
    }
  }

  /**
   * ----------------
   * D3 Setup methods
   * ----------------
   */

  /**
   * Sets the contextual d3 renderer
   */
  // @ts-expect-error
  _setRenderer(container) {
    // Remove all the elements except those that have the attribute `data-chart-keep`
    // and their children
    // @ts-expect-error
    this.chartRenderer
      ?.selectAll('*')
      .filter(function () {
        // @ts-expect-error
        let element = this;
        while (element && element.nodeType === 1) {
          if (element.hasAttribute('data-chart-keep')) return false;
          element = element.parentNode;
        }
        return true;
      })
      .remove();
    // @ts-expect-error
    this.chartRenderer = select(container);
  }

  /**
   * -------------------------
   * Scales management methods
   * -------------------------
   */

  /**
   * Returns the Y Scale extrema
   * @returns {[yMin, yMax]} Minimal and Maximal values for the Y Scale
   */
  // @ts-expect-error
  _getYExtrema(timeseries) {
    // @ts-expect-error
    let values = timeseries.map(({ data }) => data?.value);
    return getExtrema(values, this.defaultYMax, this.headroom);
  }

  /**
   * Returns the chart D3 Y Scale
   * @param {*} timeseries Timevalues array
   * @returns {D3 Scale} Chart Y Scale
   */
  // @ts-expect-error
  _getYScale(timeseries) {
    let [yMin, yMax] = this._getYExtrema(timeseries);

    return scaleLinear()
      .domain([yMin, yMax])
      .rangeRound([this.chartInnerHeight, this.chartSpacing.yScaleTop]);
  }

  /**
   * Returns the chart D3 X Scale
   * @param {*} timeseries Timevalues array
   * @returns {D3 Scale} Chart X Scale
   */
  // @ts-expect-error
  _getXScale(timeseries) {
    return (
      scaleBand()
        // @ts-expect-error
        .domain(timeseries.map(d => d.time))
        .range([this.chartSpacing.left, this.chartWidth])
    );
  }

  /**
   * Sets the chart scales to the chart context
   * @param {*} timeseries Timevalues array
   */
  // @ts-expect-error
  _setScales(timeseries) {
    // @ts-expect-error
    this.yScale = this._getYScale(timeseries);
    // @ts-expect-error
    this.xScale = this._getXScale(timeseries);
  }

  /**
   * -----------------------
   * Axis management methods
   * -----------------------
   */

  /**
   * Draws the X Axis of the graph based on a given scale
   * @param {*} scale D3 scale
   */
  // @ts-expect-error
  _setXAxis(scale, tickFormatter) {
    let xAxis = axisBottom(scale).tickFormat(tickFormatter).ticks(this.timeseries.length);

    // @ts-expect-error
    this.chartRenderer
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${[0, this.chartInnerHeight + this.chartSpacing.vertical]})`)
      .call(xAxis)
      .selectAll('.tick')
      .attr('class', 'x-axis-tick')
      // @ts-expect-error
      .attr('data-test-cashflow-chart-x-tick', (_, index) => index);

    // @ts-expect-error
    this.chartRenderer.selectAll('.x-axis-tick text').attr('y', this.chartSpacing.vertical * 2);
  }

  /**
   * Draws the Y Axis of the graph based on a given scale
   * @param {*} scale D3 Y scale
   */
  // @ts-expect-error
  _setYAxis(scale, showBalance) {
    let yAxis = axisLeft(scale)
      // @ts-expect-error
      .tickFormat(d => metricSuffixer(d))
      .ticks(6);

    // @ts-expect-error
    this.chartRenderer
      .append('g')
      .attr('class', 'y-axis')
      .classed('hidden', !showBalance) // Needed on resize
      .attr('transform', `translate(${[this.chartSpacing.leftYAxis, 0]})`)
      .call(yAxis)
      .selectAll('.tick')
      .attr('class', 'y-axis-tick')
      // @ts-expect-error
      .attr('data-test-cashflow-chart-y-tick', (_, index) => index);
  }

  /**
   * Draws the graph horizontal (Y) grid lines based on given scale
   * @param {*} scale D3 Y scale
   */
  // @ts-expect-error
  _setYGridlines(scale) {
    let tickSize = -this.chartWidth;
    let gridAxis = axisLeft(scale).ticks(6).tickSize(tickSize).tickFormat('');
    let zeroAxis = axisLeft(scale).tickValues([0]).tickSize(tickSize).tickFormat('');

    // @ts-expect-error
    this.chartRenderer
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${[this.chartSpacing.left, 0]})`)
      .call(gridAxis)
      .selectAll('.tick')
      // @ts-expect-error
      .attr('data-test-cashflow-chart-grid-line', (_, index) => index);

    // @ts-expect-error
    this.chartRenderer
      .append('g')
      .attr('class', 'grid grid--zero')
      .attr('transform', `translate(${[this.chartSpacing.left, 0]})`)
      .call(zeroAxis)
      .selectAll('.tick')
      // @ts-expect-error
      .attr('data-test-cashflow-chart-zero-grid-line', (_, index) => index);
  }

  /**
   * ---------------------------
   * Positionning helper methods
   * ---------------------------
   */

  /**
   * Returns X Position for chart line paths
   * @param {*} index Timevalue index
   * @param {*} timeseries Timevalue array
   * @param {*} scale D3 X Scale
   * @param {*} isCurrent The path represents a current timevalue
   * @returns {number} X Position
   */
  // @ts-expect-error
  _getLineXPosition(index, timeseries, scale, isCurrent, isSettled) {
    // @ts-expect-error
    if (this.args.timeseries) {
      this._captureAndReportToSentry(index, timeseries);
    }

    let time = timeseries[index].time;
    let isExtremum = index === timeseries.length - 1 || index === 0;

    if (isExtremum) {
      return isSettled
        ? this._getSettledLineXPosition(index, timeseries, scale, isCurrent)
        : this._getDottedLineXPosition(index, timeseries, scale, isCurrent);
    } else {
      return scale(time);
    }
  }

  // @ts-expect-error
  _getSettledLineXPosition(index, timeseries, scale, isCurrent) {
    let time = timeseries[index].time;
    if (index === timeseries.length - 1) {
      return isCurrent ? scale(time) : this.chartWidth;
    } else {
      return this.chartSpacing.left;
    }
  }

  // @ts-expect-error
  _getDottedLineXPosition(index, timeseries, scale, isCurrent) {
    let time = timeseries[index].time;
    if (index === timeseries.length - 1) {
      return this.chartWidth;
    } else {
      if (isCurrent && this.currentDateIndex === 0) {
        return this.chartSpacing.left;
      }
      return isCurrent ? scale(time) : this.chartSpacing.left;
    }
  }

  /**
   * Returns Y Position for chart line paths
   * @param {*} index  Timevalue index
   * @param {*} timeseries Timevalue array
   * @param {*} scale D3 Y Scale
   * @returns {number} Y Position
   */
  // @ts-expect-error
  _getLineYPosition(index, timeseries, scale) {
    let timevalueData = timeseries[index].data;
    return scale(timevalueData.value);
  }

  /**
   * --------------------
   * Line drawing methods
   * --------------------
   */

  /**
   * Draws the chart tendency lines and current situation dot
   * @param {*} timeseries
   * @param {*} xScale
   * @param {*} yScale
   */
  _drawBalance(
    // @ts-expect-error
    timeseries,
    // @ts-expect-error
    xScale,
    // @ts-expect-error
    yScale,
    isCashflowOverview = true,
    showProjectedForecast = false
  ) {
    let hasTendency = timeseries.length > 1;
    if (hasTendency) {
      if (this.isCurrentPeriodDisplayed || this.isPastRange) {
        this._drawBalanceLine(timeseries, xScale, yScale);
      }
      if (this.isCurrentPeriodDisplayed) {
        let index = this.currentDateIndex;
        let isCurrent = this.isCurrentPeriodDisplayed;
        this._drawBalanceLineDot(timeseries, xScale, yScale, index, isCurrent);
      }
      if (this.isCurrentPeriodDisplayed || this.isFutureRange) {
        this._drawCurrentBalanceLine(timeseries, xScale, yScale, isCashflowOverview);

        if (showProjectedForecast) {
          this._drawProjectedBalanceLine(timeseries, xScale, yScale);
        }
      }
    }
  }

  /**
   * Draws a tendency line
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X base scale
   * @param {*} yScale D3 Y Scale
   */
  // @ts-expect-error
  _drawBalanceLine(timeseries, xScale, yScale) {
    let settledLines = line()
      // @ts-expect-error
      .x((_, index) =>
        this._getLineXPosition(index, timeseries, xScale, this.isCurrentPeriodDisplayed, true)
      )
      // @ts-expect-error
      .y((_, index) => this._getLineYPosition(index, timeseries, yScale))
      // @ts-expect-error
      .defined((_, index) =>
        this.isCurrentPeriodDisplayed ? index <= this.currentDateIndex : this.isPastRange
      );

    // @ts-expect-error
    let settledPath = this.chartRenderer
      .append('path')
      .attr('d', settledLines(timeseries))
      .attr('class', 'cashflow-balance')
      // @ts-expect-error
      .attr('data-test-cashflow-balance', (_, index) => index);

    if (this.isAnimated) {
      settledPath
        .attr('stroke-dasharray', '0,1')
        .transition()
        .ease(easeCubicInOut)
        .attrTween('stroke-dasharray', function () {
          // @ts-expect-error
          let length = this.getTotalLength();
          return interpolate(`0,${length}`, `${length},${length}`);
        })
        .duration(this.animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(this.animationDuration);
    }
  }

  /**
   * Draws a dotted tendency line
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X base scale
   * @param {*} yScale D3 Y scale
   */
  // @ts-expect-error
  _drawCurrentBalanceLine(timeseries, xScale, yScale, isCashflowOverview = true) {
    let isCurrent = this.isCurrentPeriodDisplayed;
    let currentPeriodDefinition = this.isFutureRange
      ? timeseries
      : timeseries.slice(this.currentDateIndex);

    if (currentPeriodDefinition.length === 1) {
      currentPeriodDefinition = [...currentPeriodDefinition, ...currentPeriodDefinition];
    }

    let currentPeriodLines = line()
      // @ts-expect-error
      .x((_, index) => this._getLineXPosition(index, currentPeriodDefinition, xScale, isCurrent))
      // @ts-expect-error
      .y((_, index) => this._getLineYPosition(index, currentPeriodDefinition, yScale, isCurrent))
      .defined(() => this.isCurrentPeriodDisplayed || this.isFutureRange);

    // @ts-expect-error
    let currentPeriodPath = this.chartRenderer
      .append('path')
      .attr('d', currentPeriodLines(currentPeriodDefinition))
      .attr('class', !isCashflowOverview ? 'cashflow-balance-dotted' : 'cashflow-balance')
      .attr('stroke-dasharray', '4')
      // @ts-expect-error
      .attr('data-test-cashflow-balance-current', (_, index) => index);

    if (this.isAnimated) {
      let animationDuration = this.animationDuration / 2;
      let currentPeriodPathLength = currentPeriodPath.node().getTotalLength() * 1.1;
      currentPeriodPath
        .attr('stroke-dashoffset', currentPeriodPathLength)
        .attr('stroke-dasharray', function () {
          let dashing = 4;
          let dashLength = dashing * 2;
          let dashCount = Math.ceil(currentPeriodPathLength / dashLength);
          let newDashes = new Array(dashCount).join(`${dashing}, ${dashing} `);
          return `${newDashes} 0, ${currentPeriodPathLength}`;
        })
        .transition()
        .ease(easeCubicInOut)
        .attr('stroke-dashoffset', 0)
        .duration(animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(animationDuration);
    } else {
      currentPeriodPath.attr('stroke-dasharray', '4');
    }
  }

  // @ts-expect-error
  _drawProjectedBalanceLine(projectedTimeseries: TimeseriesDataPoint[], xScale, yScale) {
    const currentMonth = dayjs().startOf('month');
    const endMonth = currentMonth.add(3, 'month');

    let data = projectedTimeseries
      .filter(({ time }) => {
        const timeMonth = dayjs(time).startOf('month');
        return timeMonth.isBetween(currentMonth, endMonth, 'month', '[]');
      })
      // Return X and Y for both start and end of each month
      .flatMap((datapoint, index) => {
        let isFirstRenderedMonth = index === 0;
        let applyOffset = isFirstRenderedMonth && this.currentDateIndex <= 0;
        let isCurrentMonthDataPoint = dayjs(datapoint.time).isSame(currentMonth, 'month');

        return [
          // Start of the month data point
          {
            x: applyOffset ? this.chartSpacing.left : xScale(datapoint.time),
            y: yScale(
              isCurrentMonthDataPoint
                ? datapoint.data.startingBalance
                : Number(datapoint.data.projectedStartingBalance ?? 0)
            ),
          },
          // End of the month data point
          {
            x: xScale(datapoint.time) + xScale.bandwidth(),
            y: yScale(Number(datapoint.data.projectedEndingBalance ?? 0)),
          },
        ];
      });
    let currentPeriodLines = line()
      // @ts-expect-error
      .x(d => d.x)
      // @ts-expect-error
      .y(d => d.y);

    // @ts-expect-error
    let currentPeriodPath = this.chartRenderer
      .append('path')
      .attr('d', currentPeriodLines(data))
      .attr('class', 'cashflow-balance-projected')
      .attr('stroke-dasharray', '4')
      .attr(
        'data-test-cashflow-balance-projected',
        // @ts-expect-error
        (_, index) => index
      );

    if (this.isAnimated) {
      let animationDuration = this.animationDuration / 2;
      let currentPeriodPathLength = currentPeriodPath.node().getTotalLength() * 1.1;
      currentPeriodPath
        .attr('stroke-dashoffset', currentPeriodPathLength)
        .attr('stroke-dasharray', function () {
          let dashing = 4;
          let dashLength = dashing * 2;
          let dashCount = Math.ceil(currentPeriodPathLength / dashLength);
          let newDashes = new Array(dashCount).join(`${dashing}, ${dashing} `);
          return `${newDashes} 0, ${currentPeriodPathLength}`;
        })
        .transition()
        .ease(easeCubicInOut)
        .attr('stroke-dashoffset', 0)
        .duration(animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(animationDuration);
    } else {
      currentPeriodPath.attr('stroke-dasharray', '4');
    }
  }

  /**
   * Draws the line current situation dot
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X base scale
   * @param {*} yScale D3 Y Scale
   * @param {*} index Dot position index
   * @param {*} isCurrent Is reflecting a current situation
   */
  // @ts-expect-error
  _drawBalanceLineDot(timeseries, xScale, yScale, index, isCurrent) {
    let dotRadius = 3;
    let animationDuration = this.animationDuration / 2;
    // @ts-expect-error
    let balanceDot = this.chartRenderer
      .selectAll('cashflow-balance-dot')
      .data([timeseries[index]])
      .enter()
      .append('circle')
      .attr('class', 'cashflow-balance-dot')
      // @ts-expect-error
      .attr('data-test-cashflow-balance-dot', (_, index) => index)
      .attr('cx', this._getLineXPosition(index, timeseries, xScale, isCurrent, true))
      .attr('cy', this._getLineYPosition(index, timeseries, yScale))
      .attr('r', this.isAnimated ? 0 : dotRadius);

    if (this.isAnimated) {
      balanceDot
        .transition()
        .attr('r', dotRadius)
        .duration(animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(animationDuration);
    }
  }

  /**
   * --------------------
   * Bars drawing methods
   * --------------------
   */

  /**
   * Draws chart double bars
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X Scale
   * @param {*} yScale D3 Y Scale
   * @param {*} showProjectedForecast boolean to show projected data
   */
  // @ts-expect-error
  _drawBars(timeseries, xScale, yScale, isCashflowOverview = true, showProjectedForecast = false) {
    let barEaseBackOffset = 0.1;
    let generalHeight = this.chartInnerHeight + this.chartSpacing.top;
    let hasV2Forecast = this.abilities.can('view forecast v2 cash-flow');
    let v2Classname = '-v2';
    let cashflowBarClassname = `cashflow-flow-bar${hasV2Forecast && !isCashflowOverview ? v2Classname : ''}`;

    // @ts-expect-error
    let getBarHeight = value => Math.abs(yScale(0) - yScale(value));
    // @ts-expect-error
    let getBarInitialHeight = value => yScale(0) - getBarHeight(value) * barEaseBackOffset;

    // @ts-expect-error
    let flowDataSelection = this.chartRenderer
      .selectAll('.cashflow-flow-container')
      .data(timeseries)
      .enter();

    let flowContainer = flowDataSelection
      .append('g')
      .attr('class', 'cashflow-flow-container')
      // @ts-expect-error
      .attr('data-test-cashflow-flow-container', (_, index) => index)
      .attr('width', xScale.bandwidth())
      .attr('height', generalHeight - yScale(0))
      // @ts-expect-error
      .attr('transform', timevalue => `translate(${xScale(timevalue.time)}, 0)`);

    let flowBarDataSelection = flowContainer
      .selectAll('.cashflow-flow-bar')
      // @ts-expect-error
      .data((timevalue, index) => this._getFlowBarsData(timevalue, index))
      .enter()
      .append('rect')
      // @ts-expect-error
      .attr('class', ({ type }) => `${cashflowBarClassname} ${type}`)
      .attr('data-test-cashflow-flow-bar', '')
      .attr('x', xScale.bandwidth() / 2)
      .attr('rx', ({ projectedValue, value }: { projectedValue: number; value: number }) => {
        return showProjectedForecast && projectedValue > value ? 0 : 2;
      });

    if (this.isAnimated) {
      flowBarDataSelection = flowBarDataSelection
        // @ts-expect-error
        .attr('y', flow => getBarInitialHeight(flow.value))
        // @ts-expect-error
        .attr('height', flow => getBarHeight(flow.value) * barEaseBackOffset)
        .transition()
        .ease(easeBackInOut)
        .duration(this.animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(this.animationDuration);
    }

    if (hasV2Forecast && showProjectedForecast) {
      flowContainer
        .selectAll('.cashflow-projected-forecast-bar')
        // @ts-expect-error
        .data((timevalue, index) => this._getFlowBarsData(timevalue, index))
        .enter()
        .append('rect')
        .attr(
          'class',
          ({ type }: { type: string }) => `${cashflowBarClassname} ${type} projected-forecast`
        )
        .attr('data-test-cashflow-projected-forecast-bar', '')
        .attr('x', xScale.bandwidth() / 2)
        .attr('rx', 2)
        .attr('y', ({ projectedValue }: { projectedValue: number }) =>
          projectedValue < 0 ? yScale(0) : yScale(projectedValue)
        )
        .attr('height', ({ projectedValue }: { projectedValue: number }) =>
          isNaN(getBarHeight(projectedValue)) ? 0 : getBarHeight(projectedValue)
        );

      flowContainer
        .selectAll('.cashflow-projected-forecast-bar-separator')
        // @ts-expect-error
        .data((timevalue, index) => this._getFlowBarsData(timevalue, index))
        .enter()
        .append('rect')
        .attr(
          'class',
          ({ type }: { type: string }) =>
            `${cashflowBarClassname} ${type} projected-forecast-separator`
        )
        .attr('data-test-cashflow-projected-forecast-bar-separator', '')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', function ({ projectedValue, value }: { projectedValue: number; value: number }) {
          if (value > 0 && projectedValue > value) {
            return yScale(value);
          }
          return -10;
        })
        .attr(
          'height',
          function ({ projectedValue, value }: { projectedValue: number; value: number }) {
            if (value > 0 && projectedValue > value) {
              return 2;
            }
            return 0;
          }
        );
    }

    flowBarDataSelection
      // @ts-expect-error
      .attr('y', ({ value }) => (value < 0 ? yScale(0) : yScale(value)))
      // @ts-expect-error
      .attr('height', ({ value }) => getBarHeight(value));

    // Forecast stacked bars
    flowContainer
      .selectAll('.cashflow-forecast-bar')
      // @ts-expect-error
      .data((timevalue, index) => this._getFlowBarsData(timevalue, index))
      .enter()
      .append('rect')
      // @ts-expect-error
      .attr('class', ({ type }) => {
        if (hasV2Forecast) {
          return `${cashflowBarClassname} ${type}-forecast`;
        }
        return `${cashflowBarClassname} ${type} forecast`;
      })
      .attr('data-test-cashflow-forecast-bar', '')
      .attr('x', xScale.bandwidth() / 2) // Offset for forecast bar
      .attr('rx', 2)
      // @ts-expect-error
      .attr('y', ({ forecastValue }) => (forecastValue < 0 ? yScale(0) : yScale(forecastValue)))
      // @ts-expect-error
      .attr('height', d =>
        isNaN(getBarHeight(d.forecastValue)) ? 0 : getBarHeight(d.forecastValue)
      );
  }

  /**
   * ----------
   * Animations
   * ----------
   */

  // @ts-expect-error
  _cumulateDelay(animationDuration) {
    this.animationDelay += animationDuration;
  }

  _resetDelay() {
    this.animationDelay = 0;
  }

  // @ts-expect-error
  _captureAndReportToSentry(index, timeseries) {
    let extra = {
      index,
      timeseriesLength: timeseries.length,
      timeseriesFirstTime: timeseries.length ? timeseries[0]?.time : 'none',
      timeseriesLastTime: timeseries.length ? timeseries.at(-1)?.time : 'none',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startOfToday: dayjs().startOf('day').toDate(),
      bounds: {
        start: this.startBound,
        end: this.endBound,
      },
    };

    if (index < 0) {
      Sentry.captureMessage('Overview bug debug: Index is negative', {
        extra,
      });
    }

    if (index >= timeseries.length) {
      Sentry.captureMessage('Overview bug debug: Index is higher than timeseries length', {
        extra,
      });
    }

    if (timeseries.includes(undefined)) {
      Sentry.captureMessage('Overview bug debug: Timeseries includes `undefined` element', {
        extra,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::ComboChart': typeof ComboChartComponent;
  }
}
