export default {
  "header": "cdc",
  "center": "cdq",
  "header-placeholder": "cdZ",
  "placeholder": "cdR",
  "name-block": "cdQ",
  "block": "cde",
  "detail-block": "cdB",
  "border-top": "cdE"
};
