export default {
  "row": "cDv",
  "cell": "cDo",
  "title": "cDn",
  "internal-note": "cDi",
  "unit": "cDA",
  "price": "cDY",
  "quick-actions": "cDh",
  "missing-details-container": "cDf",
  "missing-details-label": "cDK body-2",
  "warning-icon": "cDG",
  "empty": "cDr",
  "checkbox": "cDb",
  "align-checkbox": "cDy"
};
