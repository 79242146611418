export default {
  "custom-period-wrapper": "cCs",
  "fadeIn": "cCF",
  "custom-period-description": "cCg body-2",
  "header": "cCD",
  "arrow-left": "cCV",
  "body": "cCL",
  "period-wrapper": "cCT",
  "back-button": "cCz",
  "footer": "cCa"
};
