export default {
  "mandate-list-item": "ctR",
  "selected": "ctQ",
  "status-avatar": "cte",
  "infos": "ctB",
  "info-title": "ctE body-1",
  "info-title-suspended": "ctd",
  "info-title-status-suspended": "ctI",
  "info-mandate-count": "ctt body-2"
};
