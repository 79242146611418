export default {
  "item-status": "cJT",
  "item-date": "cJz",
  "item-amount": "cJa body-1",
  "item-amount-credit": "cJH",
  "row": "cJO",
  "icon": "cJm",
  "disabled": "cJp",
  "icon-container": "cJx",
  "text-container": "cJw",
  "text-row": "cJN",
  "item-details": "cJv",
  "counterparty-name": "cJo",
  "column": "cJn",
  "item-amount-disabled": "cJi",
  "attachment-tooltip": "cJA",
  "bank-account-avatar": "cJY"
};
