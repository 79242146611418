export default {
  "details-box": "clt",
  "picto": "clM",
  "picto-status": "clP",
  "ghost": "cll",
  "primary-info": "clX",
  "date": "clC",
  "account-infobox": "clk",
  "declined-disclaimer": "clJ",
  "nrc-download": "cls"
};
