export default {
  "invitations-password-form": "cBo",
  "mobile-mode": "cBn",
  "logo": "cBi",
  "header": "cBA",
  "controls": "cBY",
  "footer": "cBh",
  "submit-cta": "cBf",
  "subtitle": "cBK"
};
