export default {
  "widgets-container": "ckL",
  "subtitle-container": "ckT",
  "indicator-container": "ckz",
  "transactions-container": "cka",
  "indicator": "ckH",
  "cashflow-container": "ckO",
  "cashflow": "ckm",
  "donut-container": "ckp",
  "donut": "ckx"
};
