export default {
  "option": "cgd",
  "account-description": "cgI",
  "account-identification": "cgt",
  "account-avatar": "cgM",
  "account-name": "cgP body-2",
  "account-balance": "cgl body-2",
  "external-accounts-disclaimer": "cgX",
  "status": "cgC body-2",
  "failed-connection": "cgk"
};
