export default {
  "container": "cex",
  "header": "cew",
  "content": "ceN",
  "info-list": "cev",
  "info-label": "ceo body-2",
  "info-value": "cen body-2",
  "provider": "cei",
  "policy-container": "ceA",
  "policy-label": "ceY body-2",
  "policy-number": "ceh body-2",
  "copy-to-clipboard": "cef",
  "button": "ceK btn btn--primary btn--stretch btn--large"
};
