export default {
  "integration-item": "cQJ",
  "icon-container": "cQs",
  "content": "cQF",
  "title-container": "cQg",
  "new-badge": "cQD",
  "title": "cQV",
  "description": "cQL",
  "no-attributes": "cQT",
  "connect-cta": "cQz"
};
