export default {
  "bucket-item": "cQQ",
  "button": "cQe",
  "content": "cQB",
  "bucket-icon": "cQE",
  "bucket-title-container": "cQd",
  "bucket-new-badge": "cQI",
  "bucket-title": "cQt",
  "arrow-icon": "cQM"
};
