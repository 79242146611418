export default {
  "wrapper": "cJh",
  "header": "cJf",
  "fadeIn": "cJK",
  "header-title": "cJG title-4",
  "transactions-container": "cJr",
  "placeholder-title": "cJb",
  "transaction-item-list-container": "cJy",
  "transaction-item-list": "cJU",
  "item": "cJj"
};
