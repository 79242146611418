export default {
  "sidebar-section": "ceX body-2",
  "sidebar-section-header": "ceC body-1 mb-4",
  "providers-logos": "cek",
  "risks": "ceJ",
  "tag": "ces tag",
  "external-link-icon": "ceF",
  "disclaimer": "ceg",
  "divider": "ceD"
};
