export default {
  "header": "csy",
  "top-section": "csU",
  "close": "csj btn btn--icon-only btn--tertiary",
  "container": "csW",
  "picto": "csu",
  "details": "cFS",
  "expiration-date": "cFc",
  "payment-method": "cFq"
};
