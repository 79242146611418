export default {
  "row": "cFI",
  "cell": "cFt body-1",
  "row-cancelled": "cFM",
  "cell-amount": "cFP",
  "avatar-standalone": "cFl",
  "empty": "cFX",
  "align-right": "cFC",
  "debitor-container": "cFk",
  "debitor-info": "cFJ",
  "debitor-name": "cFs body-1",
  "with-sidebar": "cFF",
  "method-col": "cFg",
  "resource-number": "cFD caption",
  "resource-icon": "cFV",
  "avatar": "cFL"
};
