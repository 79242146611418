export default {
  "card": "cXq",
  "header": "cXZ",
  "avatar": "cXR",
  "title": "cXQ",
  "subtitle": "cXe",
  "label": "cXB",
  "item": "cXE body-2",
  "header-icon": "cXd",
  "mt-8": "cXI"
};
