export default {
  "donut-chart-container": "cXH",
  "donut-chart-wrapper": "cXO",
  "chart": "cXm",
  "donut-chart-legend-container": "cXp caption",
  "donut-chart-legend-item-empty": "cXx",
  "donut-chart-legend-bullet-empty": "cXw",
  "donut-chart-legend-bullet": "cXN",
  "donut-chart-legend-text-empty": "cXv",
  "donut-chart-legend-text": "cXo",
  "donut-chart-custom-label-uncategorized": "cXn",
  "custom-label-uncategorized-button": "cXi body-1",
  "donut-chart-legend": "cXA",
  "donut-chart-legend-item": "cXY",
  "donut-chart-legend-other-item": "cXh",
  "donut-chart-legend-uncategorized-item": "cXf",
  "donut-chart-legend-text-name": "cXK",
  "tooltip": "cXG"
};
