export default {
  "header-cell": "cda",
  "empty": "cdH",
  "header-content": "cdO caption-bold",
  "active": "cdm",
  "align-right": "cdp",
  "first-col": "cdx",
  "mid-col": "cdw",
  "status-col": "cdN"
};
