export default {
  "member-details": "ctP",
  "header": "ctl",
  "no-email": "ctX",
  "header-img": "ctC",
  "invited-persona": "ctk",
  "revoked-persona": "ctJ",
  "invited-icon": "cts",
  "revoked-icon": "ctF",
  "revised": "ctg",
  "invitable-icon": "ctD",
  "dropdown": "ctV",
  "header-body": "ctL",
  "status": "ctT",
  "name": "ctz",
  "email": "cta caption-bold",
  "actions": "ctH body-2",
  "actions-cards": "ctO",
  "actions-transactions": "ctm",
  "body": "ctp",
  "expense-permissions-section": "ctx",
  "body-title": "ctw title-4",
  "body-attr": "ctN",
  "body-label": "ctv",
  "attr-buttons": "cto",
  "body-role": "ctn small",
  "details-actions": "cti",
  "actions-item": "ctA",
  "disclaimer": "ctY",
  "permissions": "cth",
  "permission": "ctf",
  "permission-label": "ctK",
  "permission-check": "ctG",
  "permission-missing": "ctr",
  "editable-section": "ctb",
  "hris-placeholder": "cty",
  "imported-from": "ctU",
  "integration-logo": "ctj",
  "permission-value": "ctW",
  "tooltip": "ctu",
  "check-icon": "cMS"
};
