export default {
  "fieldset": "cEe",
  "numbering-format": "cEB",
  "numbering-fields": "cEE",
  "input-settings": "cEd",
  "label-tooltip": "cEI",
  "tooltip-icon": "cEt",
  "next-invoice-number": "cEM",
  "spacing": "cEP"
};
