export default {
  "fullscreen-modal": "cgu",
  "creation-form-modal": "cDS",
  "links-container": "cDc",
  "link-field": "cDq",
  "link": "cDZ",
  "add-link": "cDR",
  "remove-link": "cDQ",
  "flex-row": "cDe",
  "flex-item": "cDB",
  "unit-trigger": "cDE",
  "dynamic-placeholders": "cDd"
};
