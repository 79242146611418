export default {
  "account-container": "csC",
  "badge": "csk",
  "subtitle": "csJ title-4",
  "accounts-filter": "css",
  "account-filter-label": "csF",
  "status": "csg",
  "account-info": "csD",
  "balance": "csV",
  "revealed-balance": "csL",
  "hidden-balance": "csT",
  "mr-12": "csz",
  "disabled-selector": "csa"
};
