export default {
  "container": "cEp",
  "form-container": "cEx",
  "title": "cEw",
  "title-border": "cEN",
  "tabs-container": "cEv",
  "preview-container": "cEo",
  "email-preview-container": "cEn",
  "close-button": "cEi btn btn--icon-only btn--tertiary btn--large"
};
