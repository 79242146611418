export default {
  "container": "cdj",
  "mapped": "cdW",
  "header": "cdu",
  "title": "cIS caption",
  "select-option-label": "cIc",
  "content": "cIq",
  "item": "cIZ body-2",
  "empty": "cIR"
};
