export default {
  "wrapper": "ceB",
  "content": "ceE",
  "back": "ced btn btn--tertiary",
  "heading": "ceI",
  "heading-content": "cet",
  "logo": "ceM",
  "description": "ceP body-2",
  "cta": "cel"
};
