export default {
  "name-wrapper": "cFa",
  "preset-name": "cFH",
  "invoices-entry-period-container": "cFO",
  "transactions-entry-period-container": "cFm",
  "period-wrapper": "cFp",
  "period-btn": "cFx",
  "cancel-placeholder": "cFw",
  "period-cancel-btn": "cFN",
  "transactions-entry-container": "cFv",
  "invoices-entry-container": "cFo",
  "dropdown": "cFn",
  "dropdown-opened": "cFi",
  "dropdown-closed": "cFA"
};
