export default {
  "notifications-settings": "cPp",
  "opened": "cPx",
  "icon": "cPw",
  "header": "cPN",
  "header-toggle": "cPv",
  "avatar-image": "cPo",
  "title": "cPn",
  "icon--right": "cPi",
  "toggle-container": "cPA",
  "toggle": "cPY"
};
