export default {
  "section-item": "cCO",
  "sm": "cCm",
  "xs": "cCp",
  "section-item-label": "cCx body-2",
  "section-item-value": "cCw body-2",
  "positive": "cCN",
  "negative": "cCv",
  "hidden": "cCo",
  "section-item-caption": "cCn caption"
};
