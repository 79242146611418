export default {
  "members-loading": "cMi",
  "members-loading--ff": "cMA",
  "header": "cMY",
  "header-search-bar": "cMh",
  "body": "cMf",
  "members": "cMK",
  "placeholder-container": "cMG",
  "icon": "cMr",
  "details": "cMb",
  "member-loading": "cMy",
  "member-loading--no-ff": "cMU",
  "body-members": "cMj",
  "body-details": "cMW"
};
