export default {
  "wrapper": "cJB",
  "header": "cJE",
  "fadeIn": "cJd",
  "header-content": "cJI",
  "with-cta": "cJt",
  "header-title": "cJM title-4",
  "advanced-filters-trigger": "cJP",
  "filters-button": "cJl",
  "filters-button-upsell": "cJX",
  "filters-upsell-item": "cJC",
  "filters-badge": "cJk",
  "chart-container": "cJJ",
  "chart-legend-container": "cJs mb-24",
  "chart-watermark-container": "cJF",
  "chart-watermark": "cJg",
  "placeholder-title": "cJD",
  "placeholder-subtitle": "cJV",
  "advanced-filters": "cJL"
};
