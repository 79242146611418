export default {
  "company-logo": "cPg",
  "list-item": "cPD",
  "link": "cPV",
  "title-wrapper": "cPL",
  "title": "cPT body-2",
  "subtitle": "cPz caption",
  "truncated-text": "cPa",
  "image-placeholder": "cPH"
};
