export default {
  "y-axis-placeholder": "cXl",
  "y-axis-navigable": "cXX",
  "y-axis-tick": "cXC",
  "x-axis-placeholder": "cXk",
  "x-axis-navigable": "cXJ",
  "x-axis-tick-wrapper": "cXs",
  "x-axis-tick": "cXF",
  "chart-loading-renderer": "cXg"
};
