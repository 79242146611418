export default {
  "file-uploader": "cVq",
  "file-uploader-content": "cVZ",
  "file-uploader-dropzone": "cVR",
  "fixedSize": "cVQ",
  "two-column": "cVe",
  "file-uploader-label": "cVB body-1",
  "required-chip": "cVE",
  "animated-container": "cVd",
  "toggle-button": "cVI",
  "toggle-button-icon": "cVt"
};
