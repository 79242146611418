export default {
  "wrapper": "ckw",
  "cashflow-status": "ckN",
  "status": "ckv",
  "estimated": "cko",
  "vat-due-amount": "ckn title-3",
  "vat-tooltip": "cki",
  "icon-info": "ckA",
  "charts-wrapper": "ckY",
  "chart-error": "ckh",
  "chart-controls": "ckf",
  "amount-loading": "ckK"
};
