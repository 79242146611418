export default {
  "bullet": "cCb",
  "slice-0": "cCy",
  "slice-1": "cCU",
  "slice-2": "cCj",
  "slice-3": "cCW",
  "slice-4": "cCu",
  "slice-5": "ckS",
  "slice-6": "ckc",
  "slice-other": "ckq",
  "slice-uncategorized": "ckZ",
  "label": "ckR",
  "ellipsis": "ckQ",
  "rest": "cke",
  "previous-date-range": "ckB caption"
};
